export const usersData = [
  { userEmail: 'yuliya.kozhevnikova@odysmtech.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'ivan.pekarchuk@odysmtech.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'alexey.martynov@odysmtech.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'vtb@mail.ru', userPassword: '123', userGroup: 'test' },
  { userEmail: 'vtb1@mail.ru', userPassword: '123', userGroup: 'test' },
  { userEmail: 'vtb2@mail.ru', userPassword: '123', userGroup: 'test' },
  { userEmail: 'alexey.rostov@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'nikolay.solomanchuk@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'alexander.sirovatskiy@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'elena.kustova@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'igor.ekimenkov@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'ekaterina.bobrova@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'elena.abashkina@mediainstinctgroup.ru', userPassword: '123', userGroup: 'admin' },
  { userEmail: 'anastasiya.shemyakina@mediainstinctgroup.ru', userPassword: '123', userGroup: 'test' },
  { userEmail: 'elena.svidlova@mediainstinctgroup.ru', userPassword: '123', userGroup: 'test' },
  { userEmail: 'elena.svidlova@mediainstinctgroup.ru', userPassword: 'test2', userGroup: 'test2' },
  { userEmail: 'elena.svidlova@mediainstinctgroup.ru', userPassword: 'test3', userGroup: 'test3' },
];
