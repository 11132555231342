import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './ResultChart.module.css';
import { Line } from 'react-chartjs-2';
import { setSelectedValueReachTv, setSelectedValueReachOlv, setSelectedValueBudget, setSelectedValueReach, setSelectedValueCprp, setSelectedShareOlv, setSelectedShareTv, setSumOfRatingsTv, setSelectedValueImpressions } from '../../../../bll/chart_reducer';
import { round } from '../../../../helpers/chart_helpers';

export const ResultChart = React.memo(props => {
  const chartData = useSelector(state => state.chart.chartData);
  const allDataFromBack = useSelector(state => state.chart.allDataFromBack);
  const dispatch = useDispatch();
  const options = {
    onClick: function (evt, element) {
      if (element.length > 0) {
        const ind = element[0].index;
        const currentBudget = +chartData.labels[ind].split(/\s+/).join('');
        const currentReach = +chartData.datasets[0].data[ind];
        const currentCprp = currentBudget / currentReach;

        const filteredData = allDataFromBack.filter(item => item.media === 'tv_olv').sort((a, b) => a.budget - b.budget);
        const selectedObj = filteredData.find(item => item.budget === currentBudget);
        const currentShareOlv = selectedObj['Share OLV'];
        const currentShareTv = 1 - currentShareOlv;

        const filteredDataTv = allDataFromBack.filter(item => item.media === 'tv').sort((a, b) => a.budget - b.budget);
        const currentObgTv = filteredDataTv.find(item => item.budget === currentBudget);
        const currentSumOfRatingsTv = currentObgTv.TRP;
        const currentReachTv = +currentObgTv.rfPlus;

        const filteredDataOlv = allDataFromBack.filter(item => item.media === 'olv').sort((a, b) => a.budget - b.budget);
        const currentObgOlv = filteredDataOlv.find(item => item.budget === currentBudget);
        const currentReachOlv = +currentObgOlv.rfPlus;
        const currentImpressionsOlv = +currentObgOlv.impressions;

        dispatch(setSelectedValueBudget(currentBudget));
        dispatch(setSelectedValueReach(currentReach));
        dispatch(setSelectedValueReachTv(currentReachTv));
        dispatch(setSelectedValueReachOlv(currentReachOlv));
        dispatch(setSelectedValueCprp(currentCprp));
        dispatch(setSelectedShareOlv(currentShareOlv));
        dispatch(setSelectedShareTv(currentShareTv));
        dispatch(setSumOfRatingsTv(currentSumOfRatingsTv));
        dispatch(setSelectedValueImpressions(currentImpressionsOlv));
      }
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },

    elements: {
      point: {
        radius: 0,
        hoverRadius: 6,
        backgroundColor: 'red',
        pointBorderColor: 'red',
      },
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxHeight: 1,
        },
        align: 'start',
      },
      tooltip: {
        enabled: true,
        titleColor: 'black',
        backgroundColor: '#EBF2F7',
        titleFont: { weight: 'bold', size: 20 },
        bodyColor: 'black',
        boxHeight: null,
        boxWidth: null,
        usePointStyle: 'true',
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
          },
          title: function (TooltipItem) {
            return TooltipItem[0].label + ' руб';
          },
        },
      },
    },
    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          align: 'center',
          text: `Охват, %`,
          color: 'black',
          font: {
            size: 13,
          },
          padding: { top: 5, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(val);
          },
        },
      },
      x: {
        title: {
          display: true,
          align: 'center',
          text: 'Объем инвестиций, руб.',
          color: 'black',
          font: {
            size: 13,
          },
        },
      },
    },
  };
  return (
    <div className={s.wrapper}>
      <Line height={350} data={chartData} options={options} />
    </div>
  );
});
