import React from 'react';
import s from './Header.module.css';
import logo from '../../../assets/images/logo.png';

export const Header = React.memo(props => {
  const { marginBottom } = props;
  return (
    <div className={s.wrapper}>
      <img src={logo} className={s.logo} alt='logo' style={{ marginBottom: marginBottom }} />
    </div>
  );
});
