import { API } from '../dal/api';
import { setError, setStatus, setInfo } from './app_reducer';
import { makeTaItems, makePeriodItems, makeTaArrForAdminClientWithoutClientName } from '../helpers/chart_helpers';

const SET_TA = 'SET_TA';
const SET_PERIOD = 'SET_PERIOD';
const SET_FREQUENCY = 'SET_FREQUENCY';
const SET_MEDIUM_HRONO = 'SET_MEDIUM_HRONO';
const SET_MAX_SHARE_OLV = 'SET_MAX_SHARE_OLV';
const SET_TA_ITEMS_ARR = 'SET_TA_ITEMS_ARR';
const SET_PERIOD_ITEMS_ARR = 'SET_PERIOD_ITEMS_ARR';
const SET_MAX_SHARE_OLV_ITEMS_ARR = 'SET_MAX_SHARE_OLV_ITEMS_ARR ';
const SET_TA_ARR_FOR_ADMIN_CLIENT = 'SET_TA_ARR_FOR_ADMIN_CLIENT';
const SET_CHROMO_SHARE_ARR = 'SET_CHROMO_SHARE_ARR';
const SET_MARGIN_CHRONO = 'SET_MARGIN_CHRONO';

const initialState = {
  ta: '',
  period: '',
  frequency: '',
  mediumHrono: 20,
  maxShareOlv: '',
  taItemsArr: [],
  periodItemsArr: [],
  maxShareOlvItemsArr: [],
  taArrForAdminClient: [],
  chronoShareArr: [
    { name: '5', value: '' },
    { name: '10', value: '' },
    { name: '15', value: '' },
    { name: '20', value: '100' },
    { name: '25', value: '' },
    { name: '30', value: '' },
  ],
  marginChrono: 0,
};

export const inputParameters_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TA:
      return { ...state, ta: action.ta };
    case SET_PERIOD:
      return { ...state, period: action.period };
    case SET_FREQUENCY:
      return { ...state, frequency: action.frequency };
    case SET_MEDIUM_HRONO:
      return { ...state, mediumHrono: action.mediumHrono };
    case SET_MAX_SHARE_OLV:
      return { ...state, maxShareOlv: action.maxShareOlv };
    case SET_TA_ITEMS_ARR:
      return { ...state, taItemsArr: action.taItemsArr };
    case SET_PERIOD_ITEMS_ARR:
      return { ...state, periodItemsArr: action.periodItemsArr };
    case SET_MAX_SHARE_OLV_ITEMS_ARR:
      return { ...state, maxShareOlvItemsArr: action.maxShareOlvItemsArr };
    case SET_TA_ARR_FOR_ADMIN_CLIENT:
      return { ...state, taArrForAdminClient: action.taArrForAdminClient };
    case SET_CHROMO_SHARE_ARR:
      return { ...state, chronoShareArr: action.chronoShareArr };
    case SET_MARGIN_CHRONO:
      return { ...state, marginChrono: action.marginChrono };
    default:
      return state;
  }
};

// action creators
export const setTa = ta => ({ type: SET_TA, ta });
export const setPeriod = period => ({ type: SET_PERIOD, period });
export const setFrequency = frequency => ({ type: SET_FREQUENCY, frequency });
export const setMediumHrono = mediumHrono => ({ type: SET_MEDIUM_HRONO, mediumHrono });
export const setMaxShareOlv = maxShareOlv => ({ type: SET_MAX_SHARE_OLV, maxShareOlv });
export const setTaItemsArr = taItemsArr => ({ type: SET_TA_ITEMS_ARR, taItemsArr });
export const setPeriodItemsArr = periodItemsArr => ({ type: SET_PERIOD_ITEMS_ARR, periodItemsArr });
export const setMaxShareOlvItemsArr = maxShareOlvItemsArr => ({ type: SET_MAX_SHARE_OLV_ITEMS_ARR, maxShareOlvItemsArr });
export const setTaArrForAdminClient = taArrForAdminClient => ({ type: SET_TA_ARR_FOR_ADMIN_CLIENT, taArrForAdminClient });
export const setChronoShareArr = chronoShareArr => ({ type: SET_CHROMO_SHARE_ARR, chronoShareArr });
export const setMarginChrono = marginChrono => ({ type: SET_MARGIN_CHRONO, marginChrono });

export const setStartData = userGroup => async dispatch => {
  try {
    dispatch(setStatus('loading'));

    const response = await API.get_startData({
      Client: userGroup,
    });
    if (response.data.TA) {
      const TaItems = makeTaItems(response.data.TA);
      const PeriodItems = makePeriodItems(response.data.Month);
      const MaxShareOlvItems = response.data['Share OLV'].map(item => `${item * 100} %`);

      dispatch(setTaItemsArr(TaItems));
      dispatch(setPeriodItemsArr(PeriodItems));
      dispatch(setMaxShareOlvItemsArr(MaxShareOlvItems));

      dispatch(setTa(TaItems[0]));
      dispatch(setPeriod(PeriodItems[0]));
      dispatch(setFrequency('1 +'));
      dispatch(setMaxShareOlv(MaxShareOlvItems[0]));
    } else {
      dispatch(setError(`В базе нет данных по ${userGroup}`));
    }

    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const setStartDataForAdminClient = selectClient => async dispatch => {
  try {
    dispatch(setStatus('loading'));
    const response = await API.get_startData({
      Client: selectClient,
    });
    const taArrForAdminClient = response.data.TA ? makeTaArrForAdminClientWithoutClientName(response.data.TA) : [];

    dispatch(setTaArrForAdminClient(taArrForAdminClient));

    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const sendDataFromUser = data => async (dispatch, getState) => {
  const currentUserLogin = getState().app.currentUserLogin;
  const selectedClient = getState().app.selectedClient;
  dispatch(setInfo('Данные отправлены на сервер'));
  try {
    await API.post_UserData({
      dataFromUser: data,
      userLogin: currentUserLogin,
      selectClient: selectedClient,
    });
  } catch (error) {}
};
export const sendEmail = () => async (dispatch, getState) => {
  const currentUserLogin = getState().app.currentUserLogin;
  const selectedClient = getState().app.selectedClient;

  try {
    await API.post_send_email({
      receiver_email: currentUserLogin,
      client: selectedClient,
      TA_list: ['М 20-45 BC'],
    });
  } catch (error) {}
};
