import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { inputParameters_reducer } from './inputParameters_reducer';
import { app_reducer } from './app_reducer';
import { chart_reducer } from './chart_reducer';

const rootReducer = combineReducers({
  input: inputParameters_reducer,
  app: app_reducer,
  chart: chart_reducer,
});
const input = sessionStorage.getItem('input')
  ? JSON.parse(sessionStorage.getItem('input'))
  : {
      ta: '',
      period: '',
      frequency: '',
      mediumHrono: 20,
      maxShareOlv: '',
      taItemsArr: [],
      periodItemsArr: [],
      mediumHronoItemsArr: [],
      taArrForAdminClient: [],
      chronoShareArr: [
        { name: '5', value: '' },
        { name: '10', value: '' },
        { name: '15', value: '' },
        { name: '20', value: '100' },
        { name: '25', value: '' },
        { name: '30', value: '' },
      ],
      marginChrono: 0,
    };

const chart = sessionStorage.getItem('chart')
  ? JSON.parse(sessionStorage.getItem('chart'))
  : {
      chartData: [],
      donutData: [],
      selectedValueBudget: null,
      selectedValueReach: null,
      selectedValueReachTv: null,
      selectedValueReachOlv: null,
      selectedValueCprp: null,
      allDataFromBack: [],
      selectedShareOlv: null,
      selectedShareTv: null,
      sumOfRatingsTv: null,
      selectedValueImpressions: null,
    };

const app = sessionStorage.getItem('app')
  ? JSON.parse(sessionStorage.getItem('app'))
  : {
      status: 'success',
      error: null,
      info: null,
      isInitialized: false,
      currentUserLogin: '',
      currentUserGroup: '',
      clientArrForAdmin: [],
      selectedClient: '',
    };

const persistedState = { input, chart, app };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunkMiddleware)));

store.subscribe(() => {
  sessionStorage.setItem('input', JSON.stringify(store.getState().input));
  sessionStorage.setItem('chart', JSON.stringify(store.getState().chart));
  sessionStorage.setItem('app', JSON.stringify(store.getState().app));
});
