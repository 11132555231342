import { usersData } from '../assets/data/users';
import { uniqueClentArr } from '../helpers/chart_helpers';
import { setStartData } from './inputParameters_reducer';
import { getChartData } from './chart_reducer';

const SET_STATUS = 'SET_STATUS';
const SET_ERROR = 'SET_ERROR';
const SET_IS_INITIALIZED = 'SET_IS_INITIALIZED';
const SET_CURRENT_USER_LOGIN = 'SET_CURRENT_USER_LOGIN';
const SET_INFO = 'SET_INFO';
const SET_CURRENT_USER_GROUP = 'SET_CURRENT_USER_GROUP';
const SET_CLIENT_ARR_FOR_ADMIN = 'SET_CLIENT_ARR_FOR_ADMIN';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

const initialState = {
  status: 'success',
  error: null,
  info: null,
  isInitialized: false,
  currentUserLogin: '',
  currentUserGroup: '',
  clientArrForAdmin: [],
  selectedClient: '',
};

export const app_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, status: action.status };
    case SET_ERROR:
      return { ...state, error: action.error };
    case SET_IS_INITIALIZED:
      return { ...state, isInitialized: action.isInitialized };
    case SET_CURRENT_USER_LOGIN:
      return { ...state, currentUserLogin: action.currentUserLogin };
    case SET_INFO:
      return { ...state, info: action.info };
    case SET_CURRENT_USER_GROUP:
      return { ...state, currentUserGroup: action.currentUserGroup };
    case SET_CLIENT_ARR_FOR_ADMIN:
      return { ...state, clientArrForAdmin: action.clientArrForAdmin };
    case SET_SELECTED_CLIENT:
      return { ...state, selectedClient: action.selectedClient };
    default:
      return state;
  }
};

// action creators
export const setStatus = status => ({ type: SET_STATUS, status });
export const setError = error => ({ type: SET_ERROR, error });
export const setInitialized = isInitialized => ({ type: SET_IS_INITIALIZED, isInitialized });
export const setCurrentUserLogin = currentUserLogin => ({ type: SET_CURRENT_USER_LOGIN, currentUserLogin });
export const setInfo = info => ({ type: SET_INFO, info });
export const setCurrentUserGroup = currentUserGroup => ({ type: SET_CURRENT_USER_GROUP, currentUserGroup });
export const setClientArrForAdmin = clientArrForAdmin => ({ type: SET_CLIENT_ARR_FOR_ADMIN, clientArrForAdmin });
export const setSelectedClient = selectedClient => ({ type: SET_SELECTED_CLIENT, selectedClient });
//thunk creators

export const signIn = (userEmail, userPassword) => async dispatch => {
  const loadingStartData = async userGroup => {
    await dispatch(setStartData(userGroup));
    userGroup !== 'admin' && (await dispatch(getChartData(userGroup)));
    dispatch(setInitialized(true));
  };

  const currentUser = usersData.find(item => item.userEmail === userEmail.toLowerCase() && item.userPassword === userPassword);

  currentUser && dispatch(setCurrentUserGroup(currentUser.userGroup));
  currentUser && dispatch(setCurrentUserLogin(userEmail));
  currentUser ? loadingStartData(currentUser.userGroup) : dispatch(setError('Введен неверный логин или пароль'));

  const clentArr = uniqueClentArr(usersData);
  currentUser && currentUser.userGroup === 'admin' && dispatch(setClientArrForAdmin(clentArr));
  localStorage.setItem(
    'app',
    JSON.stringify({
      timestamp: Date.now(),
    })
  );
};
