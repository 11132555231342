import React, { useState } from 'react';
import s from './EditableSpan.module.css';
import { findValueView } from '../../../../helpers/chart_helpers';

export const EditableSpan = React.memo(props => {
  const { value, onChange, name, location, isDisabled } = props;
  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(value);

  const activateEditMode = () => {
    setEditMode(true);
    setTitle(value);
  };
  const disactivateEditMode = () => {
    setEditMode(false);
    onChange(title, name);
  };
  const onChangeHandler = (e, prev) => {
    e.currentTarget.value === '0' ? setTitle('') : setTitle(e.currentTarget.value);
  };

  return editMode ? (
    <div>
      <input name={name} autoFocus onFocus={e => e.currentTarget.select()} type='number' min='0' step='1' onChange={onChangeHandler} onBlur={disactivateEditMode} value={title} className={isDisabled ? `${s.span} ${s.disabled}` : s.span} />
    </div>
  ) : (
    <div>
      <span style={{ cursor: 'pointer' }} onClick={activateEditMode} className={isDisabled ? `${s.span} ${s.disabled}` : s.span}>
        {value === '' ? value : findValueView(value, location)}
      </span>
    </div>
  );
});
