import React from 'react';
import { useSelector } from 'react-redux';
import s from './ModalWarning.module.css';

export const ModalWarning = React.memo(props => {
  const { modalActive, setModalActive, warning, setWarning } = props;
  const selectedClient = useSelector(state => state.app.selectedClient);

  const onСontinueUpload = () => {
    setModalActive(false);
    setWarning(null);
  };

  const onStopUpload = () => {
    setModalActive(false);
  };

  return (
    <div className={modalActive ? s.modal + ' ' + s.active : s.modal}>
      <div className={s.card}>
        <div className={s.btnBlock}></div>
        <div className={s.titleBlock}>
          У клиента {selectedClient} уже есть цедевая аудитория {warning}
        </div>

        <div className={s.btnUploadBlock}>
          <button className={s.btn} onClick={onСontinueUpload} disabled={false}>
            ЗАМЕНИТЬ
          </button>
          <button className={s.btn} onClick={onStopUpload} disabled={false}>
            ОСТАНОВИТЬ
          </button>
        </div>
      </div>
    </div>
  );
});
