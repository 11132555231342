import React from 'react';
import { useSelector} from 'react-redux';
import s from './PieChart.module.css';
import { Pie } from 'react-chartjs-2';

export const PieChart = React.memo(props => {
  const selectedShareOlv = useSelector(state => state.chart.selectedShareOlv);
  const selectedShareTv = useSelector(state => state.chart.selectedShareTv);
  const selectedValueBudget = useSelector(state => state.chart.selectedValueBudget);
  const selectedValueBudgetOlv = +selectedValueBudget*selectedShareOlv
  const selectedValueBudgetTv = +selectedValueBudget*selectedShareTv

  const donutData = {
    datasets: [{label: "Pie", data: [+selectedShareTv*100, +selectedShareOlv*100], backgroundColor: ["#44546A", "#8FAADC"], hoverOffset: 4}],
    labels: ["ТВ", "OLV"]
  }
 
  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          return value + '₽'
        },
        labels: {
          title: {
            font: {
              size: '14px',
              weight: 'bold',
            },
          },
        },
        anchor: 'end',
        align: 'start',
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'centre',
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
        callbacks: {
          label: function (TooltipItem) {
            return TooltipItem.label === 'ТВ' ?  `${selectedValueBudgetTv.toLocaleString()} ₽ `+ '('+ TooltipItem.parsed +' %)' : `${selectedValueBudgetOlv.toLocaleString()} ₽ `+ '('+ TooltipItem.parsed +' %)' 
          },
          title: function (TooltipItem) {  
            return  TooltipItem[0].label
          },       
        },
      },
    },
  };

  return (
    <div className={s.wrapper}>
      <Pie width={250} height={500} data={donutData} options={options} />
    </div>
  );
});
