import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import s from './DetailPage.module.css';
import { Header } from '../../components/Header/Header';
import { DetailRow } from '../../components/common/DetailRow/DetailRow';
import { DetailButton } from '../../components/common/DetailButton/DetailButton';
import { PieChart } from '../../components/Charts/PieChart/PieChart';
import { useHistory } from 'react-router-dom';
import { round } from '../../../helpers/chart_helpers';
import { ExportPPT } from '../../components/ExportPpt/ExportPPT';
import { ExportXLS } from '../../components/ExportXLS/ExportXLS';
import rightArrow from '../../../assets/images/rightArrow.png';

export const DetailPage = React.memo(props => {
  const history = useHistory();
  const ta = useSelector(state => state.input.ta);
  const period = useSelector(state => state.input.period);
  const frequency = useSelector(state => state.input.frequency);
  const mediumHrono = useSelector(state => state.input.mediumHrono);
  const selectedValueBudget = useSelector(state => state.chart.selectedValueBudget);
  const selectedValueReach = useSelector(state => state.chart.selectedValueReach);
  const selectedValueReachTv = useSelector(state => state.chart.selectedValueReachTv);
  const selectedValueReachOlv = useSelector(state => state.chart.selectedValueReachOlv);
  const selectedShareOlv = useSelector(state => state.chart.selectedShareOlv);
  const selectedShareTv = useSelector(state => state.chart.selectedShareTv);
  const sumOfRatingsTv = useSelector(state => state.chart.sumOfRatingsTv);
  const selectedValueImpressions = useSelector(state => state.chart.selectedValueImpressions);

  const sumOfRatingsTvForOneWeek = +sumOfRatingsTv / 4;
  const selectedValueBudgetOlv = +selectedValueBudget * selectedShareOlv;
  const selectedValueBudgetTv = +selectedValueBudget * selectedShareTv;

  const [isExportPPT, setIsExportPPT] = useState(false);

  const onClickBack = () => {
    history.push('/chart');
  };

  const onCliclExportPpt = () => {
    setIsExportPPT(true);
  };

  useEffect(() => {
    const timer1 = setTimeout(() => setIsExportPPT(false), 2000);
    return () => clearTimeout(timer1);
  }, [isExportPPT]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <div className={s.wrapper}>
      <Header />
      <div className={s.content}>
        <div className={s.arrowBlock}>
          <img src={rightArrow} className={s.arrowRight} alt='arrowRight' />
        </div>
        <div className={s.detailBlock}>
          <div className={s.detail}>
            <div className={s.title}>Детализация</div>
            <DetailRow parameter={'Доля ТВ'} parameterValue={+selectedShareTv * 100 + ' %'} />
            <DetailRow parameter={'Бюджет ТВ (руб)'} parameterValue={selectedValueBudgetTv.toLocaleString()} />
            <DetailRow parameter={'Доля OLV'} parameterValue={+selectedShareOlv * 100 + ' %'} />
            <DetailRow parameter={'Бюджет OLV (руб)'} parameterValue={selectedValueBudgetOlv.toLocaleString()} bottom />
          </div>
          <div className={s.detailKpi}>
            <div className={s.title}>KPI</div>
            <DetailRow parameter={'ЦА'} parameterValue={ta} />
            <DetailRow parameter={'Период для планирования'} parameterValue={period} />
            <DetailRow parameter={'Совокупный охват ТВ + OLV'} parameterValue={`${frequency}, ${round(selectedValueReach, 1)}%`} />
            <DetailRow parameter={'Охват только ТВ'} parameterValue={`${frequency}, ${round(selectedValueReachTv, 1)}%`} />
            <DetailRow parameter={'Охват только OLV'} parameterValue={`${frequency}, ${round(selectedValueReachOlv, 1)}%`} />
            <DetailRow parameter={'Средний хронометраж в ТВ'} parameterValue={mediumHrono} />
            <DetailRow parameter={'Недельный вес при условии четырех недель размещения'} parameterValue={round(sumOfRatingsTvForOneWeek) + ' TRP'} />
            <DetailRow parameter={'Сумма impressions OLV'} parameterValue={round(selectedValueImpressions).toLocaleString()} />
            <DetailRow parameter={'Сумма рейтингов по ТВ'} parameterValue={round(sumOfRatingsTv) + ' TRP'} bottom />
          </div>
          <div className={s.detailBtn}>
            <DetailButton onClick={onClickBack} title={'Вернуться назад'} isDisabled={false} />
          </div>
          <div className={s.exportExelBtn}>
            <ExportXLS />
          </div>
          <div className={s.exportPptBtn}>
            <DetailButton onClick={onCliclExportPpt} title={'Выгрузка PPT'} isDisabled={false} isExport />
          </div>
        </div>
        <div className={s.chartBlock}>
          <PieChart />
        </div>
        {isExportPPT && <ExportPPT />}
      </div>
    </div>
  );
});
