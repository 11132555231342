import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import s from './HomePage.module.css';
import { Header } from '../../components/Header/Header';
import { StartButton } from '../../components/common/StartButton/StartButton';
import { ModalChoiceClient } from '../../components/common/ModalChoiceClient/ModalChoiceClient';
import { ModalWarning } from '../../components/common/ModalWarning/ModalWarning';
import grade from '../../../assets/images/grade.png';
import dashboard from '../../../assets/images/dashboard.png';
import { useHistory } from 'react-router-dom';
import { getChartData } from '../../../bll/chart_reducer';
import { setError, setStatus } from '../../../bll/app_reducer';
import { sendDataFromUser } from '../../../bll/inputParameters_reducer';
import { checkTaFromUser, checkXlFromUser, checkTaOnRepeat, getCorrectNameOfColomns_TV_CBU, getCorrectNameOfColomns_TV_CPP_10mln } from '../../../helpers/chart_helpers';

export const HomePage = React.memo(() => {
  const [modalGroupSelectionActive, setModalGroupSelectionActive] = useState(false);
  const [modalWarningActive, setModalWarningActive] = useState(false);

  const [warning, setWarning] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const taArrForAdminClient = useSelector(state => state.input.taArrForAdminClient);
  const currentUserGroup = useSelector(state => state.app.currentUserGroup);
  const dispatch = useDispatch();
  const history = useHistory();
  const onClickEvaluateTheNewFlight = () => {
    dispatch(getChartData(currentUserGroup));
    history.push('/chart');
  };

  const fileRef = React.createRef();

  const onImportExcel = file => {
    dispatch(setStatus('loading'));
    const { files } = file.target;
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        let data = [];
        let TV_CBU = [];
        let TV_CPP_10mln = [];
        TV_CBU = workbook.Sheets.hasOwnProperty('TV_CBU') && TV_CBU.concat(XLSX.utils.sheet_to_json(workbook.Sheets['TV_CBU'], { raw: true }));
        TV_CPP_10mln = workbook.Sheets.hasOwnProperty('TV_CPP_10mln') && TV_CPP_10mln.concat(XLSX.utils.sheet_to_json(workbook.Sheets['TV_CPP_10mln'], { raw: true }));

        data = {
          TV_CBU: getCorrectNameOfColomns_TV_CBU(TV_CBU),
          TV_CPP_10mln: getCorrectNameOfColomns_TV_CPP_10mln(TV_CPP_10mln),
        };

        const errXl = checkXlFromUser(TV_CBU, TV_CPP_10mln);
        const err = TV_CBU && TV_CPP_10mln && checkTaFromUser(TV_CBU, TV_CPP_10mln);
        const warn = TV_CBU && TV_CPP_10mln && checkTaOnRepeat(TV_CBU, taArrForAdminClient);

        if (warn !== null) {
          setModalWarningActive(true);
          setWarning(warn);
        }
        if (errXl !== null) {
          dispatch(setError(errXl));
        }
        if (errXl === null && err !== null) {
          dispatch(setError(err));
        }
        if (errXl === null && err === null) {
          setUploadData(data);
        }
        dispatch(setStatus('success'));
      } catch (error) {
        dispatch(setError(error.message));
      }
    };
    fileReader.readAsBinaryString(files[0]);
  };
  const onClickUploadData = () => {
    setModalGroupSelectionActive(true);
  };
  const onClickModalUploadBtn = () => {
    fileRef.current.click();
    setModalGroupSelectionActive(false);
  };

  useEffect(() => {
    warning === null && uploadData !== null && dispatch(sendDataFromUser(uploadData));
  }, [warning, uploadData]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.wrapper}>
      <Header marginBottom={30} />
      <div className={s.content}>
        <div className={s.centerBlock}>
          <div className={s.title}>Flow Budget Video Planner</div>
          <div className={s.btnsBlock}>
            {/* <StartButton onClick={() => {}} title={'Дашборд\n существующего\n флайта'} btnText = {'ПЕРЕЙТИ'}>
              <img src={dashboard} alt='dashboard' style={{ width: '40px', height: '40px' }} />
            </StartButton> */}
            <StartButton onClick={onClickUploadData} title={'Загрузить\n данные\n для ЦА'} btnText={'ЗАГРУЗИТЬ'}>
              <img src={dashboard} alt='dashboard' style={{ width: '40px', height: '40px' }} />
            </StartButton>
            <StartButton onClick={onClickEvaluateTheNewFlight} title={'Оценить новый\n TV & OLV флайт'} btnText={'ПЕРЕЙТИ'}>
              <img src={grade} alt='grade' style={{ width: '40px', height: '40px' }} />
            </StartButton>
            <input type='file' accept='.xlsx, .xls' onChange={onImportExcel} ref={fileRef} style={{ display: 'none' }} />
          </div>
        </div>
      </div>
      {modalGroupSelectionActive && <ModalChoiceClient modalActive={modalGroupSelectionActive} setModalActive={setModalGroupSelectionActive} onClickModalUploadBtn={onClickModalUploadBtn} />}
      {modalWarningActive && <ModalWarning modalActive={modalWarningActive} setModalActive={setModalWarningActive} warning={warning} setWarning={setWarning} />}
    </div>
  );
});
