import React from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-data-export';
import { round } from '../../../helpers/chart_helpers';
import { DetailButton } from '../../components/common/DetailButton/DetailButton';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExportXLS = props => {
  const ta = useSelector(state => state.input.ta);
  const period = useSelector(state => state.input.period);
  const frequency = useSelector(state => state.input.frequency);
  const mediumHrono = useSelector(state => state.input.mediumHrono);
  const selectedValueBudget = useSelector(state => state.chart.selectedValueBudget);
  const selectedValueReach = useSelector(state => state.chart.selectedValueReach);
  const selectedValueReachTv = useSelector(state => state.chart.selectedValueReachTv);
  const selectedValueReachOlv = useSelector(state => state.chart.selectedValueReachOlv);
  const selectedShareOlv = useSelector(state => state.chart.selectedShareOlv);
  const selectedShareTv = useSelector(state => state.chart.selectedShareTv);
  const sumOfRatingsTv = useSelector(state => state.chart.sumOfRatingsTv);
  const selectedValueImpressions = useSelector(state => state.chart.selectedValueImpressions);

  const sumOfRatingsTvForOneWeek = +sumOfRatingsTv / 4;
  const selectedValueBudgetOlv = +selectedValueBudget * selectedShareOlv;
  const selectedValueBudgetTv = +selectedValueBudget * selectedShareTv;
  const width = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
  const width2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24];
  const col1 = width.map(item => {
    return {
      title: '',
      style: {
        font: { bold: true, color: { rgb: 'ffffff' }, sz: 9, name: 'Tahoma' },
        alignment: { wrapText: false, horizontal: 'bottom', vertical: 'center' },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });

  col1.splice(1, 0, {
    title: 'Детализация',
    width: { wpx: 170 },
    style: {
      font: { bold: true, color: { rgb: 'ffffff' }, sz: 10, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: '1f4e78' } },
      alignment: { wrapText: false, horizontal: 'bottom', vertical: 'center' },
      border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: '1f4e78' } } },
    },
  });
  col1.splice(2, 0, {
    title: '',
    width: { wpx: 167 },
    style: {
      fill: { patternType: 'solid', fgColor: { rgb: '1f4e78' }, sz: 10, name: 'Tahoma' },
      alignment: { wrapText: true, vertical: 'center' },
      border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data0 = width2.map(item => {
    return {
      value: '',
      style: {
        fill: { patternType: 'solid', fgColor: { rgb: 'ddebf7' } },
        alignment: { wrapText: false, horizontal: 'right' },
        border: { bottom: { style: 'thin', color: { rgb: 'ddebf7' } }, top: { style: 'thin', color: { rgb: 'ddebf7' } }, left: { style: 'thin', color: { rgb: 'ddebf7' } }, right: { style: 'thin', color: { rgb: 'ddebf7' } } },
      },
    };
  });
  data0.splice(0, 1);
  data0.splice(0, 1);
  data0.splice(1, 0, {
    value: 'Flow Budget',
    style: {
      font: { bold: true, sz: 20, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ddebf7' } },
      alignment: { wrapText: false, horizontal: 'right' },
      border: { bottom: { style: 'thin', color: { rgb: 'ddebf7' } }, top: { style: 'thin', color: { rgb: 'ddebf7' } }, left: { style: 'thin', color: { rgb: 'ddebf7' } }, right: { style: 'thin', color: { rgb: 'ddebf7' } } },
    },
  });
  data0.splice(2, 0, {
    value: 'Video Planner',
    style: {
      font: { bold: true, sz: 20, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ddebf7' } },
      alignment: { wrapText: false, horizontal: 'left' },
      border: { bottom: { style: 'thin', color: { rgb: 'ddebf7' } }, top: { style: 'thin', color: { rgb: 'ddebf7' } }, left: { style: 'thin', color: { rgb: 'ddebf7' } }, right: { style: 'thin', color: { rgb: 'ddebf7' } } },
    },
  });

  const data1 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        font: { sz: 9, name: 'Tahoma' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data1.splice(1, 0, {
    value: 'Доля ТВ',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data1.splice(2, 0, {
    value: +selectedShareTv,
    style: {
      numFmt: '0%',
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'bottom', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data2 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data2.splice(1, 0, {
    value: 'Бюджет ТВ (руб)',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data2.splice(2, 0, {
    value: selectedValueBudgetTv,
    style: {
      numFmt: '#,##0',
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'bottom', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data4 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data4.splice(1, 0, {
    value: 'Бюджет OLV (руб)',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data4.splice(2, 0, {
    value: selectedValueBudgetOlv,
    style: {
      numFmt: '#,##0',
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'bottom', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data5 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data5.splice(1, 0, {
    value: 'KPI',
    style: {
      font: { bold: true, color: { rgb: 'ffffff' }, sz: 11, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: '1f4e78' } },
      alignment: { wrapText: false, horizontal: 'bottom', vertical: 'center' },
      border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: '1f4e78' } } },
    },
  });
  data5.splice(2, 0, {
    value: '',
    style: {
      fill: { patternType: 'solid', fgColor: { rgb: '1f4e78' } },
      alignment: { wrapText: true, vertical: 'center' },
      border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data6 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data6.splice(1, 0, {
    value: 'ЦА',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data6.splice(2, 0, {
    value: ta,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: false, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data7 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data7.splice(1, 0, {
    value: 'Период для планирования',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data7.splice(2, 0, {
    value: period,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data8 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data8.splice(1, 0, {
    value: 'Совокупный охват ТВ + OLV',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data8.splice(2, 0, {
    value: `${frequency}, ${round(selectedValueReach, 1)}%`,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data9 = width.map(item => {
    return {
      value: '',
      style: {
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data9.splice(1, 0, {
    value: 'Охват только ТВ',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data9.splice(2, 0, {
    value: `${frequency}, ${round(selectedValueReachTv, 1)}%`,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data10 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data10.splice(1, 0, {
    value: 'Охват только OLV',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data10.splice(2, 0, {
    value: `${frequency}, ${round(selectedValueReachOlv, 1)}%`,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data11 = width.map(item => {
    return {
      value: '',
      style: {
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data11.splice(1, 0, {
    value: 'Средний хронометраж в ТВ',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data11.splice(2, 0, {
    value: +mediumHrono,
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data12 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data12.splice(1, 0, {
    value: 'Недельный вес при условии четырех недель размещения',
    style: {
      font: { sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'left', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data12.splice(2, 0, {
    value: round(sumOfRatingsTvForOneWeek) + ' TRP',
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data13 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data13.splice(1, 0, {
    value: 'Сумма impressions OLV',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data13.splice(2, 0, {
    value: round(selectedValueImpressions),
    style: {
      numFmt: '#,##0',
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'bottom', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data14 = width.map(item => {
    return {
      value: '',
      style: {
        alignment: { vertical: 'center' },
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data14.splice(1, 0, {
    value: 'Сумма рейтингов по ТВ',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data14.splice(2, 0, {
    value: round(sumOfRatingsTv) + ' TRP',
    style: {
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'right', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const data3 = width.map(item => {
    return {
      value: '',
      style: {
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });
  data3.splice(1, 0, {
    value: 'Доля OLV',
    style: {
      alignment: { vertical: 'center' },
      font: { sz: 9, name: 'Tahoma' },
      fill: { patternType: 'solid', fgColor: { rgb: 'f2f7fc' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'f2f7fc' } }, left: { style: 'thin', color: { rgb: 'f2f7fc' } }, right: { style: 'thin', color: { rgb: 'f2f7fc' } } },
    },
  });
  data3.splice(2, 0, {
    value: selectedShareOlv,
    style: {
      numFmt: '0%',
      font: { bold: true, sz: 9, name: 'Tahoma' },
      alignment: { wrapText: true, horizontal: 'bottom', vertical: 'center' },
      fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
      border: { bottom: { style: 'thin', color: { rgb: 'B4C5D2' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
    },
  });
  const emptyRow = width2.map(item => {
    return {
      value: '',
      style: {
        fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
        border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'fffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
      },
    };
  });

  const sheet1 = [
    {
      xSteps: 0,
      ySteps: 0,
      columns: width2.map(item => {
        return {
          title: '',
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ddebf7' } },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: 'ddebf7' } }, top: { style: 'thin', color: { rgb: 'ddebf7' } }, left: { style: 'thin', color: { rgb: 'ddebf7' } }, right: { style: 'thin', color: { rgb: 'ddebf7' } } },
          },
        };
      }),

      data: [
        data0,
        width2.map(item => {
          return {
            value: '',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ddebf7' } },
              border: { bottom: { style: 'thin', color: { rgb: 'ddebf7' } }, top: { style: 'thin', color: { rgb: 'ddebf7' } }, left: { style: 'thin', color: { rgb: 'ddebf7' } }, right: { style: 'thin', color: { rgb: 'ddebf7' } } },
            },
          };
        }),
      ],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: width.map(item => {
        return {
          title: '',
          style: {
            fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
            alignment: { wrapText: true, horizontal: 'center', vertical: 'top' },
            border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
          },
        };
      }),

      data: [
        width.map(item => {
          return {
            value: '',
            style: {
              fill: { patternType: 'solid', fgColor: { rgb: 'ffffff' } },
              border: { bottom: { style: 'thin', color: { rgb: 'ffffff' } }, top: { style: 'thin', color: { rgb: 'ffffff' } }, left: { style: 'thin', color: { rgb: 'ffffff' } }, right: { style: 'thin', color: { rgb: 'ffffff' } } },
            },
          };
        }),
      ],
    },
    {
      xSteps: 0,
      ySteps: -1,
      columns: col1,
      data: [data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow, emptyRow],
    },
  ];
  return (
    <div>
      <ExcelFile hideElement={false} element={<DetailButton onClick={() => {}} title={'Выгрузка XLS'} isDisabled={false} isExport />} filename='export_flow_budget_planner'>
        <ExcelSheet dataSet={sheet1} name='Лист 1' />
      </ExcelFile>
    </div>
  );
};
