import React from 'react';
import { useSelector } from 'react-redux';
import pptxgen from 'pptxgenjs';
import { COLORS } from '../../../assets/colors';
import { round } from '../../../helpers/chart_helpers';
import logoStar from '../../../assets/images/logoStar.png';

export const ExportPPT = React.memo(props => {
  const chartData = useSelector(state => state.chart.chartData);
  const ta = useSelector(state => state.input.ta);
  const period = useSelector(state => state.input.period);
  const frequency = useSelector(state => state.input.frequency);
  const mediumHrono = useSelector(state => state.input.mediumHrono);
  const selectedValueBudget = useSelector(state => state.chart.selectedValueBudget);
  const selectedValueReach = useSelector(state => state.chart.selectedValueReach);
  const selectedValueReachTv = useSelector(state => state.chart.selectedValueReachTv);
  const selectedValueReachOlv = useSelector(state => state.chart.selectedValueReachOlv);
  const selectedShareOlv = useSelector(state => state.chart.selectedShareOlv);
  const selectedShareTv = useSelector(state => state.chart.selectedShareTv);
  const sumOfRatingsTv = useSelector(state => state.chart.sumOfRatingsTv);
  const selectedValueImpressions = useSelector(state => state.chart.selectedValueImpressions);

  const sumOfRatingsTvForOneWeek = +sumOfRatingsTv / 4;
  const selectedValueBudgetOlv = +selectedValueBudget * selectedShareOlv;
  const selectedValueBudgetTv = +selectedValueBudget * selectedShareTv;

  let pptx = new pptxgen();
  let slide1 = pptx.addSlide();
  slide1.addText('', { x: 0, y: 0, w: 4.5, h: '100%', color: '363636', fill: { color: 'e9f4fb' } });
  slide1.addText(`Кривая накопления охвата и объема инвестиций`, { x: 5.5, y: 0, w: 4, h: 3.2, color: '#080808', fontSize: 10, bold: true, fontFace: 'Tahoma' });
  slide1.addImage({ path: logoStar, x: 8.5, y: 0.3, w: '11%', h: '7%' });
  slide1.addText(`Flow Budget Video`, { x: 0.5, y: -1, w: 10, h: 3.2, color: '#404040', fontSize: 24, bold: true, fontFace: 'Tahoma' });
  slide1.addText(`Planner`, { x: 0.5, y: -0.65, w: 10, h: 3.2, color: '#404040', fontSize: 24, bold: true, fontFace: 'Tahoma' });

  const tableData = [
    [
      { text: 'Детализация', options: { color: COLORS.chartBlue, align: 'left', valign: 'middle', fontSize: 14, bold: true, fontFace: 'Tahoma', border: { pt: 1, color: 'e9f4fb' }, margin: 0.08 } },
      { text: '', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', border: { pt: 1, color: 'e9f4fb' }, margin: 0.1 } },
    ],
    [
      { text: 'Доля ТВ', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: +selectedShareTv * 100 + ' %', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Бюджет ТВ (руб)', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: selectedValueBudgetTv.toLocaleString(), options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Доля OLV', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: +selectedShareOlv * 100 + ' %', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Бюджет OLV (руб)', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05, border: { pt: 1, color: 'e9f4fb' } } },
      { text: selectedValueBudgetOlv.toLocaleString(), options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05, border: { pt: 1, color: 'e9f4fb' } } },
    ],
    [
      { text: 'KPI', options: { color: COLORS.chartBlue, align: 'left', valign: 'middle', fontSize: 14, bold: true, fontFace: 'Tahoma', border: { pt: 1, color: 'e9f4fb' }, margin: 0.08 } },
      { text: '', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', border: { pt: 1, color: 'e9f4fb' }, margin: 0.1 } },
    ],
    [
      { text: 'ЦА', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: ta, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Период для планирования', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: period, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Совокупный охват ТВ + OLV', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: `${frequency}, ${round(selectedValueReach, 1)}%`, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Охват только ТВ', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: `${frequency}, ${round(selectedValueReachTv, 1)}%`, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Охват только OLV', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: `${frequency}, ${round(selectedValueReachOlv, 1)}%`, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Средний хронометраж в ТВ', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: mediumHrono, options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Недельный вес при условии четырех недель размещения', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: round(sumOfRatingsTvForOneWeek) + ' TRP', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Сумма impressions OLV', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05 } },
      { text: round(selectedValueImpressions).toLocaleString(), options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05 } },
    ],
    [
      { text: 'Сумма рейтингов по ТВ', options: { color: 'black', align: 'left', valign: 'middle', fontSize: 9, fontFace: 'Tahoma', margin: 0.05, border: { pt: 1, color: 'e9f4fb' } } },
      { text: round(sumOfRatingsTv) + ' TRP', options: { color: 'black', align: 'right', valign: 'middle', fontSize: 9, bold: true, fontFace: 'Tahoma', margin: 0.05, border: { pt: 1, color: 'e9f4fb' } } },
    ],
  ];
  let arrBorder1 = [
    { color: 'e9f4fb', pt: 0 },
    { color: 'e9f4fb', pt: 0 },
    { color: 'B4C5D2', pt: 0.5 },
    { color: 'e9f4fb', pt: 0 },
  ];

  slide1.addTable(tableData, {
    x: 0.5,
    y: 1.3,
    verbose: true,
    colW: [1.9, 1.9],
    border: arrBorder1,
  });

  let arrOlvCbuCprpChartData = [
    {
      name: 'ТВ + OLV',
      labels: chartData.labels,
      values: chartData.datasets[0].data.map(item => (item === 'null' ? null : item)),
    },
    {
      name: 'ТВ',
      labels: chartData.labels,
      values: chartData.datasets[1].data.map(item => (item === 'null' ? null : item)),
    },
    {
      name: 'OLV',
      labels: chartData.labels,
      values: chartData.datasets[2].data.map(item => (item === 'null' ? null : item)),
    },
  ];

  let lineChart = {
    x: 4.5,
    y: 2,
    w: '52%',
    h: '55%',

    chartColors: [COLORS.red, COLORS.chartBlue, COLORS.chartGrey],
    catAxisLabelColor: '808080',
    valAxisLabelColor: '808080',
    lineDataSymbol: 'none',
    catGridLine: { style: 'none' },
    valGridLine: { style: 'none' },
    legendPos: 'b',
    legendFontSize: 8,
    legendColor: '808080',
    showLegend: true,
    valAxisMinVal: 0,
    catAxisMinVal: 0,

    showValAxisTitle: true,
    valAxisTitle: 'Охват, %',
    valAxisTitleFontSize: 8,
    valAxisTitleAlign: 'left',

    showCatAxisTitle: true,
    catAxisTitle: 'Объем инвестиций, руб',
    catAxisTitleFontSize: 8,
    catAxisLabelFontSize: 8,
    valAxisLabelFontSize: 8,
  };

  slide1.addChart(pptx.charts.LINE, arrOlvCbuCprpChartData, lineChart);

  pptx.writeFile({ fileName: 'videobudget.pptx' });
  return <div></div>;
});
