import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../Select/Select';
import s from './ModalChoiceClient.module.css';
import { setSelectedClient } from '../../../../bll/app_reducer';
import { setStartDataForAdminClient } from '../../../../bll/inputParameters_reducer';

export const ModalChoiceClient = React.memo(props => {
  const { modalActive, setModalActive, onClickModalUploadBtn } = props;
  const dispatch = useDispatch();
  const clientArrForAdmin = useSelector(state => state.app.clientArrForAdmin);
  const selectedClient = useSelector(state => state.app.selectedClient);

  const onSelectClient = newValue => {
    dispatch(setSelectedClient(newValue));
    dispatch(setStartDataForAdminClient(newValue));
  };

  return (
    <div className={modalActive ? s.modal + ' ' + s.active : s.modal}>
      <div className={s.card}>
        <div className={s.btnBlock}>
          <button
            onClick={() => {
              setModalActive(false);
            }}
            className={s.closeButton}
          >
            &times;
          </button>
        </div>
        <div className={s.titleBlock}>Выберите клиента</div>
        {clientArrForAdmin !== [] && (
          <div className={s.selectWrapper}>
            <Select items={clientArrForAdmin} value={selectedClient} onChange={onSelectClient} name={'client'} />
          </div>
        )}
        <div className={s.btnUploadBlock}>
          <button className={s.btn} onClick={onClickModalUploadBtn} disabled={selectedClient === '' ? true : false}>
            ЗАГРУЗИТЬ
          </button>
        </div>
      </div>
    </div>
  );
});
