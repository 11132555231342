import React from 'react';
import s from './StartButton.module.css';

export const StartButton = React.memo(props => {
  const { onClick, children, title, btnText } = props;

  return (
    <div className={s.btnWrapper}>
      <div className={s.imgWrapper}>{children}</div>
      <div className={s.titleWrapper}>{title}</div>
      <button className={s.btn} onClick={onClick}>
        {btnText}
      </button>
    </div>
  );
});
