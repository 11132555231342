import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './ChartPage.module.css';
import { Header } from '../../components/Header/Header';
import { Select } from '../../components/common/Select/Select';
import { ResultChart } from '../../components/Charts/ResultChart/ResultChart';
import { DetailButton } from '../../components/common/DetailButton/DetailButton';
import { ChronoBlock } from '../../components/ChronoBlock/ChronoBlock';
import rightArrow from '../../../assets/images/rightArrow.png';
import { useHistory } from 'react-router-dom';
import { setTa, setPeriod, setFrequency, setMaxShareOlv } from '../../../bll/inputParameters_reducer';
import { getChartData } from '../../../bll/chart_reducer';
import { round } from '../../../helpers/chart_helpers';

export const ChartPage = props => {
  const [spanChange, setSpanChange] = useState('#A3A3B2');
  const [textColorChange, setTextColorChange] = useState('black');
  const [spanShadow, setSpanShadow] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const ta = useSelector(state => state.input.ta);
  const period = useSelector(state => state.input.period);
  const frequency = useSelector(state => state.input.frequency);
  const maxShareOlv = useSelector(state => state.input.maxShareOlv);

  const taItemsArr = useSelector(state => state.input.taItemsArr);
  const periodItemsArr = useSelector(state => state.input.periodItemsArr);
  const maxShareOlvItemsArr = useSelector(state => state.input.maxShareOlvItemsArr);

  const selectedValueBudget = useSelector(state => state.chart.selectedValueBudget);
  const selectedValueReach = useSelector(state => state.chart.selectedValueReach);
  const selectedValueCprp = useSelector(state => state.chart.selectedValueCprp);

  const currentUserGroup = useSelector(state => state.app.currentUserGroup);

  const onChangeTa = newValue => {
    if (ta !== newValue) {
      dispatch(setTa(newValue));
      dispatch(getChartData(currentUserGroup));
    }
  };
  const onChangePeriod = newValue => {
    if (period !== newValue) {
      dispatch(setPeriod(newValue));
      dispatch(getChartData(currentUserGroup));
    }
  };
  const onChangeFrequency = newValue => {
    if (frequency !== newValue) {
      dispatch(setFrequency(newValue));
      dispatch(getChartData(currentUserGroup));
    }
  };
  const onChangeMaxShareOlv = newValue => {
    if (maxShareOlv !== newValue) {
      dispatch(setMaxShareOlv(newValue));
      dispatch(getChartData(currentUserGroup));
    }
  };
  const onClickDetail = () => {
    history.push('/detail');
  };
  const onClickBack = () => {
    history.push('/home');
  };
  useEffect(() => {
    const changeColor = setTimeout(() => setSpanChange('red'), 1);
    const changeColor2 = setTimeout(() => setSpanChange('#A3A3B2'), 500);
    return () => clearTimeout(changeColor, changeColor2);
  }, [selectedValueBudget, selectedValueReach, selectedValueCprp]);
  useEffect(() => {
    const changeColor3 = setTimeout(() => setTextColorChange('red'), 1);
    const changeColor4 = setTimeout(() => setTextColorChange('black'), 500);
    return () => clearTimeout(changeColor3, changeColor4);
  }, [selectedValueBudget, selectedValueReach, selectedValueCprp]);
  useEffect(() => {
    const changeColor3 = setTimeout(() => setSpanShadow('1px 1px 6px 6px #ff000033'), 1);
    const changeColor4 = setTimeout(() => setSpanShadow(null), 500);
    return () => clearTimeout(changeColor3, changeColor4);
  }, [selectedValueBudget, selectedValueReach, selectedValueCprp]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.wrapper}>
      <Header />
      <div className={s.content}>
        <div className={s.inputDataBlock}>
          <div className={s.selectWrapper}>
            <Select items={taItemsArr} value={ta} onChange={onChangeTa} name={'ta'} placeholderText={'ЦА'} widthSpan={180} />
            <Select items={periodItemsArr} value={period} onChange={onChangePeriod} name={'period'} placeholderText={'Период для планирования'} widthSpan={120} />
            <Select items={['1 +', '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +', '10 +']} value={frequency} onChange={onChangeFrequency} name={'frequency'} placeholderText={'Частота'} widthSpan={50} />
            <ChronoBlock />
            <Select items={maxShareOlvItemsArr} value={maxShareOlv} onChange={onChangeMaxShareOlv} name={'maxShareOlv'} placeholderText={'Доля OLV'} widthSpan={50} />
          </div>
          <img src={rightArrow} className={s.arrowRight} alt='arrowRight' />
          {currentUserGroup === 'admin' && (
            <div className={s.backBtn}>
              <DetailButton onClick={onClickBack} title={'Вернуться назад'} isDisabled={false} />
            </div>
          )}
        </div>
        <div className={s.chartBlock}>
          <div className={s.chartWrapper}>
            <div className={s.title}>Кривая накопления охвата и объема инвестиций</div>
            <ResultChart />
            <div className={s.spansBlock}>
              {selectedValueReach !== null && (
                <span style={{ borderColor: spanChange, color: textColorChange, boxShadow: spanShadow }} className={s.span}>
                  Охват {frequency} {round(selectedValueReach, 1)} %
                </span>
              )}
              {selectedValueCprp !== null && (
                <span style={{ borderColor: spanChange, color: textColorChange, boxShadow: spanShadow }} className={s.span}>
                  CPRP {frequency} {round(selectedValueCprp).toLocaleString()} руб
                </span>
              )}
              {selectedValueBudget !== null && (
                <span style={{ borderColor: spanChange, color: textColorChange, boxShadow: spanShadow }} className={s.span}>
                  Бюджет TB + OLV без НДС: {selectedValueBudget.toLocaleString()} руб
                </span>
              )}
            </div>
          </div>
          <div className={s.detailBtn}>
            <DetailButton onClick={onClickDetail} title={'Детализация'} isDisabled={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
