import { API } from '../dal/api';
import { setError, setStatus } from './app_reducer';
import { COLORS } from '../assets/colors';
import { convertTa } from '../helpers/chart_helpers';

const SET_CHART_DATA = 'SET_CHART_DATA ';
const SET_SELECTED_VALUE_BUDGET = 'SET_SELECTED_VALUE_BUDGET';
const SET_SELECTED_VALUE_REACH = 'SET_SELECTED_VALUE_REACH';
const SET_SELECTED_VALUE_CPRP = 'SET_SELECTED_VALUE_CPRP';
const SET_ALL_DATA_FROM_BACK = 'SET_ALL_DATA_FROM_BACK';
const SET_DONUT_DATA = 'SET_DONUT_DATA';
const SET_SELECTED_SHARE_OLV = 'SET_SELECTED_SHARE_OLV';
const SET_SELECTED_SHARE_TV = 'SET_SELECTED_SHARE_TV';
const SET_SUM_OF_RATINGS_TV = 'SET_SUM_OF_RATINGS_TV';
const SET_SELECTED_VALUE_REACH_TV = 'SET_SELECTED_VALUE_REACH_TV';
const SET_SELECTED_VALUE_REACH_OLV = 'SET_SELECTED_VALUE_REACH_OLV';
const SET_SELECTED_VALUE_IMP = 'SET_SELECTED_VALUE_IMP';

const initialState = {
  chartData: [],
  donutData: [],
  selectedValueBudget: null,
  selectedValueReach: null,
  selectedValueReachTv: null,
  selectedValueReachOlv: null,
  selectedValueCprp: null,
  allDataFromBack: [],
  selectedShareOlv: null,
  selectedShareTv: null,
  sumOfRatingsTv: null,
  selectedValueImpressions: null,
};

export const chart_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_DATA:
      return { ...state, chartData: action.chartData };
    case SET_SELECTED_VALUE_BUDGET:
      return { ...state, selectedValueBudget: action.selectedValueBudget };
    case SET_SELECTED_VALUE_REACH:
      return { ...state, selectedValueReach: action.selectedValueReach };
    case SET_SELECTED_VALUE_CPRP:
      return { ...state, selectedValueCprp: action.selectedValueCprp };
    case SET_ALL_DATA_FROM_BACK:
      return { ...state, allDataFromBack: action.allDataFromBack };
    case SET_DONUT_DATA:
      return { ...state, donutData: action.donutData };
    case SET_SELECTED_SHARE_OLV:
      return { ...state, selectedShareOlv: action.selectedShareOlv };
    case SET_SELECTED_SHARE_TV:
      return { ...state, selectedShareTv: action.selectedShareTv };
    case SET_SUM_OF_RATINGS_TV:
      return { ...state, sumOfRatingsTv: action.sumOfRatingsTv };
    case SET_SELECTED_VALUE_REACH_TV:
      return { ...state, selectedValueReachTv: action.selectedValueReachTv };
    case SET_SELECTED_VALUE_REACH_OLV:
      return { ...state, selectedValueReachOlv: action.selectedValueReachOlv };
    case SET_SELECTED_VALUE_IMP:
      return { ...state, selectedValueImpressions: action.selectedValueImpressions };
    default:
      return state;
  }
};

// action creators
export const setChartData = chartData => ({ type: SET_CHART_DATA, chartData });
export const setSelectedValueBudget = selectedValueBudget => ({ type: SET_SELECTED_VALUE_BUDGET, selectedValueBudget });
export const setSelectedValueReach = selectedValueReach => ({ type: SET_SELECTED_VALUE_REACH, selectedValueReach });
export const setSelectedValueCprp = selectedValueCprp => ({ type: SET_SELECTED_VALUE_CPRP, selectedValueCprp });
export const setAllDataFromBack = allDataFromBack => ({ type: SET_ALL_DATA_FROM_BACK, allDataFromBack });
export const setDonutData = donutData => ({ type: SET_DONUT_DATA, donutData });
export const setSelectedShareOlv = selectedShareOlv => ({ type: SET_SELECTED_SHARE_OLV, selectedShareOlv });
export const setSelectedShareTv = selectedShareTv => ({ type: SET_SELECTED_SHARE_TV, selectedShareTv });
export const setSumOfRatingsTv = sumOfRatingsTv => ({ type: SET_SUM_OF_RATINGS_TV, sumOfRatingsTv });
export const setSelectedValueReachTv = selectedValueReachTv => ({ type: SET_SELECTED_VALUE_REACH_TV, selectedValueReachTv });
export const setSelectedValueReachOlv = selectedValueReachOlv => ({ type: SET_SELECTED_VALUE_REACH_OLV, selectedValueReachOlv });
export const setSelectedValueImpressions = selectedValueImpressions => ({ type: SET_SELECTED_VALUE_IMP, selectedValueImpressions });

export const getChartData = userGroup => async (dispatch, getState) => {
  const ta = getState().input.ta;
  const period = getState().input.period;
  const frequency = getState().input.frequency;
  const mediumHrono = getState().input.mediumHrono;
  const maxShareOlv = getState().input.maxShareOlv;
  const maxShareOlvPOST = +maxShareOlv.substring(0, maxShareOlv.length - 1) / 100;
  const frequencyPOST = +frequency.split(' ').join('').split('+').join('');
  const mediumHronoPOST = +mediumHrono;
  const marginChrono = getState().input.marginChrono;
  try {
    dispatch(setStatus('loading'));

    const response = await API.post_inputData({
      TA: convertTa(ta),
      Month: period.toLowerCase(),
      chrono: mediumHronoPOST,
      share_olv: maxShareOlvPOST,
      freq: frequencyPOST,
      Client: userGroup,
      marginChrono: marginChrono,
    });
    if (response.statusText === 'OK') {
      const filteredData = response.data.filter(item => item.media === 'tv_olv').sort((a, b) => a.budget - b.budget);
      const filteredDataTv = response.data.filter(item => item.media === 'tv').sort((a, b) => a.budget - b.budget);
      const filteredDataOlv = response.data.filter(item => item.media === 'olv').sort((a, b) => a.budget - b.budget);

      dispatch(
        setChartData({
          labels: filteredData.map(item => item.budget.toLocaleString()),
          datasets: [
            {
              type: 'line',
              label: 'ТВ + OLV',
              borderColor: COLORS.red,
              data: filteredData.map(item => item.rfPlus),
            },
            {
              type: 'line',
              label: 'ТВ',
              borderColor: COLORS.chartBlue,
              data: filteredDataTv.map(item => item.rfPlus),
            },
            {
              type: 'line',
              label: 'OLV',
              borderColor: COLORS.chartGrey,
              data: filteredDataOlv.map(item => item.rfPlus),
            },
          ],
        })
      );

      const currentBudget = +filteredData.map(item => item.budget)[filteredData.length - 1];
      const currentReach = +filteredData.map(item => item.rfPlus)[filteredData.length - 1];
      const currentCprp = currentBudget / currentReach;

      const currentReachTv = +filteredDataTv.map(item => item.rfPlus)[filteredDataTv.length - 1];
      const currentReachOlv = +filteredDataOlv.map(item => item.rfPlus)[filteredDataOlv.length - 1];

      const currentShareOlv = +filteredData.map(item => item['Share OLV'])[filteredData.length - 1];
      const currentShareTv = 1 - currentShareOlv;

      const currentObgTv = filteredDataTv.find(item => item.budget === currentBudget);
      const currentSumOfRatingsTv = currentObgTv.TRP;

      const currentImpressionsOlv = +filteredDataOlv.map(item => item.impressions)[filteredDataOlv.length - 1];

      dispatch(setSelectedValueBudget(currentBudget));
      dispatch(setSelectedValueReach(currentReach));

      dispatch(setSelectedValueReachTv(currentReachTv));
      dispatch(setSelectedValueReachOlv(currentReachOlv));
      dispatch(setSelectedValueCprp(currentCprp));
      dispatch(setAllDataFromBack(response.data));
      dispatch(setSelectedShareOlv(currentShareOlv));
      dispatch(setSelectedShareTv(currentShareTv));

      dispatch(setSumOfRatingsTv(currentSumOfRatingsTv));
      dispatch(setSelectedValueImpressions(currentImpressionsOlv));
      dispatch(setStatus('success'));
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};
