import Axios from 'axios';

export const API = {
  get_startData(payload) {
    return Axios.post('/get_start_data/', payload);
  },
  post_inputData(payload) {
    return Axios.post('/get_final_data/', payload);
  },
  post_UserData(payload) {
    return Axios.post('/calc_new_ta2/', payload);
  },
  post_send_email(payload) {
    return Axios.post('/send_email_test/', payload);
  },
};
// export const API = {
//   get_startData(payload) {
//     return Axios.post('https://stage.videobudget.mediainstinctgroup.ru/get_start_data/', payload);
//   },
//   post_inputData(payload) {
//     return Axios.post('https://stage.videobudget.mediainstinctgroup.ru/get_final_data/', payload);
//   },
//   post_UserData(payload) {
//     return Axios.post('https://stage.videobudget.mediainstinctgroup.ru/calc_new_ta2/', payload);
//   },
//   post_send_email(payload) {
//     return Axios.post('https://stage.videobudget.mediainstinctgroup.ru/send_email_test/', payload);
//   },
// };
