import React, { useState, useEffect } from 'react';
import s from './ChronoBlock.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { EditableSpan } from '../common/EditableSpan/EditableSpan';
import { Warning } from '../common/Warning/Warning';
import { DetailButton } from '../common/DetailButton/DetailButton';
import { setChronoShareArr, setMediumHrono, setMarginChrono } from '../../../bll/inputParameters_reducer';
import { getChartData } from '../../../bll/chart_reducer';
import { findMarginChrono } from '../../../helpers/chart_helpers';
import down from '../../../assets/images/down.png';

export const ChronoBlock = React.memo(props => {
  const [detaisListVisible, setDetailsVisible] = useState(false);
  const [warningChronoActive, setWarningChronoActive] = useState(false);
  const [currentMediumHrono, setCurrentMediumHrono] = useState('');
  const [currentMarginChrono, setCurrentMarginChrono] = useState('');

  const dispatch = useDispatch();
  const chronoShareArr = useSelector(state => state.input.chronoShareArr);
  const currentUserGroup = useSelector(state => state.app.currentUserGroup);
  const mediumHrono = useSelector(state => state.input.mediumHrono);
  const marginChrono = useSelector(state => state.input.marginChrono);

  const onChangeChromoItem = (newValue, name) => {
    const newChronoShareArr = chronoShareArr.map(item => {
      const newItem = { ...item };
      newItem.value = newValue;
      return item.name === name ? newItem : item;
    });
    const newMediumHrono = +newChronoShareArr[0].name * (+newChronoShareArr[0].value / 100) + +newChronoShareArr[1].name * (+newChronoShareArr[1].value / 100) + +newChronoShareArr[2].name * (+newChronoShareArr[2].value / 100) + +newChronoShareArr[3].name * (+newChronoShareArr[3].value / 100) + +newChronoShareArr[4].name * (+newChronoShareArr[4].value / 100) + +newChronoShareArr[5].name * (+newChronoShareArr[5].value / 100);
    const newMarginChrono = findMarginChrono(newChronoShareArr);
    dispatch(setChronoShareArr(newChronoShareArr));
    setCurrentMediumHrono(newMediumHrono);
    setCurrentMarginChrono(newMarginChrono);
  };

  const onClickAccordion = () => {
    setDetailsVisible(!detaisListVisible);
  };

  const onClickApply = () => {
    const sumChromo = +chronoShareArr[0].value + +chronoShareArr[1].value + +chronoShareArr[2].value + +chronoShareArr[3].value + +chronoShareArr[4].value + +chronoShareArr[5].value;

    if (sumChromo > 100) {
      setWarningChronoActive(true);
    }
    if (sumChromo !== 100) {
      setWarningChronoActive(true);
    } else {
      (currentMediumHrono !== mediumHrono || currentMarginChrono !== marginChrono) && dispatch(setMediumHrono(currentMediumHrono));
      (currentMediumHrono !== mediumHrono || currentMarginChrono !== marginChrono) && dispatch(setMarginChrono(currentMarginChrono));
      (currentMediumHrono !== mediumHrono || currentMarginChrono !== marginChrono) && dispatch(getChartData(currentUserGroup));
      setDetailsVisible(false);
    }
  };
  useEffect(() => {
    setCurrentMediumHrono(mediumHrono);
  }, []);
  useEffect(() => {
    setCurrentMarginChrono(marginChrono);
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.head} onClick={onClickAccordion}>
        <div className={s.placeholderText}>Средний хронометраж для ТВ</div>
        {currentMediumHrono > 0 ? (
          <div>
            {currentMediumHrono}" <img src={down} className={s.img} alt='down' />
          </div>
        ) : (
          <div>
            Ввести долю хроно <img src={down} className={s.img} alt='down' />
          </div>
        )}
      </div>
      <div className={s.details} onPointerLeave={() => {}} style={detaisListVisible ? { display: 'inLine' } : { display: 'none' }}>
        <div className={s.detailsContent}>
          <div className={s.colWrapper}>
            <div className={s.col}>
              <div className={s.item}>
                <div>5"</div>
                <EditableSpan value={chronoShareArr[0].value} name={chronoShareArr[0].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
              <div className={s.item}>
                <div>15"</div>
                <EditableSpan value={chronoShareArr[2].value} name={chronoShareArr[2].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
              <div className={s.item}>
                <div>25"</div>
                <EditableSpan value={chronoShareArr[4].value} name={chronoShareArr[4].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
            </div>
            <div className={s.col}>
              <div className={s.item}>
                <div>10"</div>
                <EditableSpan value={chronoShareArr[1].value} name={chronoShareArr[1].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
              <div className={s.item}>
                <div>20"</div>
                <EditableSpan value={chronoShareArr[3].value} name={chronoShareArr[3].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
              <div className={s.item}>
                <div>30"</div>
                <EditableSpan value={chronoShareArr[5].value} name={chronoShareArr[5].name} onChange={onChangeChromoItem} location={'percent'} />
              </div>
            </div>
          </div>
          <div className={s.applyBtnWrapper}>
            <DetailButton onClick={onClickApply} title={'Применить'} isDisabled={false} />
          </div>
        </div>
      </div>
      <Warning text={'Сумма всех хронометражей должна быть равна 100%'} warningVisible={warningChronoActive} setWarningVisible={setWarningChronoActive} />
    </div>
  );
});
