export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const makeTaItems = arr => {
  const newArr = arr.map(item => {
    const updateItem = item.join(', ');
    let newItem;
    if (updateItem.indexOf('WOMEN') > -1) {
      const str = updateItem.replace(/WOMEN/g, '');
      newItem = 'Ж'.concat(str);
    } else if (updateItem.indexOf('MEN') > -1) {
      const str = updateItem.replace(/MEN/g, '');
      newItem = 'М'.concat(str);
    } else if (updateItem.indexOf('ALL') > -1) {
      const str = updateItem.replace(/ALL/g, '');
      newItem = 'Все'.concat(str);
    } else {
      newItem = updateItem;
    }
    return newItem;
  });
  return newArr;
};

export const convertTa = ta => {
  if (ta.indexOf('Ж') > -1) {
    const str = ta.replace(/Ж/g, '');
    return 'WOMEN'.concat(str).split(', ');
  } else if (ta.indexOf('М') > -1) {
    const str = ta.replace(/М/g, '');
    return 'MEN'.concat(str).split(', ');
  } else if (ta.indexOf('Все') > -1) {
    const str = ta.replace(/Все/g, '');
    return 'ALL'.concat(str).split(', ');
  } else {
    return ta.split(', ');
  }
};

export const makeUpperCaseFirst = word => {
  const splitted = word.split('');
  const first = splitted[0].toUpperCase();
  const rest = [...splitted];
  rest.splice(0, 1);
  const result = [first, ...rest].join('');
  return result;
};

export const makePeriodItems = arr => {
  const currentMonthArr = [];
  const startMonthArr = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

  const newArr = arr.map(item => {
    const newItem = makeUpperCaseFirst(item);
    return newItem;
  });
  startMonthArr.map(i => {
    newArr.find(month => month === i && currentMonthArr.push(i));
    return i;
  });
  return currentMonthArr;
};

export const makeFrequencyItems = arr => {
  const newArr = arr.map(item => `${item} +`);
  return newArr;
};

export const uniqueClentArr = arr => {
  const newArr = [];
  arr.map(item => {
    const itemFinded = newArr.find(i => i === item.userGroup);
    if (item.userGroup !== 'admin' && !itemFinded) {
      newArr.push(item.userGroup);
    }
    return item;
  });
  return newArr;
};

export const checkTaFromUser = (TV_CBU, TV_CPP_10mln) => {
  const errArr = [];
  const uniqueTaArr = [];
  const monthArr = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];

  TV_CBU.map(item => {
    const itemFinded = TV_CPP_10mln.find(i => i.TA === item.TA);

    const isrepeatErr = errArr.find(i => i === `На листе TV_CPP_10mln нет целевой аудитории: ${item.TA}`);
    !itemFinded && !isrepeatErr && errArr.push(`На листе TV_CPP_10mln нет целевой аудитории: ${item.TA}`);

    const isUniqueTa = uniqueTaArr.find(i => i === item.TA);
    !isUniqueTa && uniqueTaArr.push(item.TA);
    return item;
  });

  uniqueTaArr.map(item => {
    const currentTaArr = TV_CBU.filter(i => i.TA === item);
    for (let i = 0; i < monthArr.length; i++) {
      const itemMonthFindedInCurrentTaArr = currentTaArr.find(it => it.Month === monthArr[i]);
      const isrepeatErr = errArr.find(i => i === `На листе TV_CBU для целевой аудитории ${item} нет данных за ${monthArr[i]}`);
      !itemMonthFindedInCurrentTaArr && !isrepeatErr && errArr.push(`На листе TV_CBU для целевой аудитории ${item} нет данных за ${monthArr[i]}`);
    }
    return item;
  });
  const err = errArr.length === 0 ? null : errArr.join(', ');

  return err;
};

export const checkXlFromUser = (TV_CBU, TV_CPP_10mln) => {
  const TV_CBU_Names_Arr = ['Month', 'TA', 'reach1', 'reach2', 'trp1', 'trp2'];
  const TV_CPP_10mln_Names_Arr = ['TA', 'CPP'];

  const errArr = [];

  !TV_CBU && errArr.push('Нет листа с именем TV_CBU');
  !TV_CPP_10mln && errArr.push('Нет листа с именем TV_CPP_10mln');

  if ((TV_CBU, TV_CPP_10mln)) {
    TV_CBU_Names_Arr.map(item => {
      const currentNamesArr = Object.keys(TV_CBU[0]);
      !currentNamesArr.find(i => i.trim() === item) && errArr.push(`На листе TV_CBU нет колонки с именем ${item}`);
      return item;
    });
    TV_CPP_10mln_Names_Arr.map(item => {
      const currentNamesArr = Object.keys(TV_CPP_10mln[0]);
      !currentNamesArr.find(i => i.trim() === item) && errArr.push(`На листе TV_CPP_10mln нет колонки с именем ${item}`);
      return item;
    });
  }

  const errXl = errArr.length === 0 ? null : errArr.join(', ');
  return errXl;
};
export const checkTaOnRepeat = (TV_CBU, taArrForAdminClient) => {
  const warningArr = [];
  const uniqueTaArr = [];
  TV_CBU.map(item => {
    const isUniqueTa = uniqueTaArr.find(i => i === item.TA);
    !isUniqueTa && uniqueTaArr.push(item.TA);
    return item;
  });
  uniqueTaArr.map(item => {
    const isRepeatTa = taArrForAdminClient.find(i => i === item);
    isRepeatTa && warningArr.push(item);
    return item;
  });

  const warn = warningArr.length === 0 ? null : warningArr.join(', ');
  return warn;
};

export const getCorrectNameOfColomns_TV_CBU = TV_CBU => {
  const namesArr = Object.keys(TV_CBU[0]);

  const indReach1 = namesArr.map(i => i.trim()).indexOf('reach1');
  const indReach2 = namesArr.map(i => i.trim()).indexOf('reach2');
  const indTrp1 = namesArr.map(i => i.trim()).indexOf('trp1');
  const indTrp2 = namesArr.map(i => i.trim()).indexOf('trp2');
  const indTA = namesArr.map(i => i.trim()).indexOf('TA');
  const indMonth = namesArr.map(i => i.trim()).indexOf('Month');

  const newTV_CBU = TV_CBU.map(i => {
    return {
      reach1: i[Object.keys(i)[indReach1]],
      reach2: i[Object.keys(i)[indReach2]],
      trp2: i[Object.keys(i)[indTrp2]],
      Month: i[Object.keys(i)[indMonth]],
      TA: i[Object.keys(i)[indTA]],
      trp1: i[Object.keys(i)[indTrp1]],
    };
  });
  return newTV_CBU;
};
export const getCorrectNameOfColomns_TV_CPP_10mln = TV_CPP_10mln => {
  const namesArr = Object.keys(TV_CPP_10mln[0]);

  const indTA = namesArr.map(i => i.trim()).indexOf('TA');
  const indCPP = namesArr.map(i => i.trim()).indexOf('CPP');

  const newTV_CPP_10mln = TV_CPP_10mln.map(item => {
    return {
      TA: item[Object.keys(item)[indTA]],
      CPP: item[Object.keys(item)[indCPP]],
    };
  });

  return newTV_CPP_10mln;
};

export const makeTaArrForAdminClientWithoutClientName = taArrForAdminClient => {
  const newTaArrForAdminClient = taArrForAdminClient.map(item => {
    return item[1];
  });
  return newTaArrForAdminClient;
};

export const findValueView = (value, location) => {
  const roundBudget = round(value).toLocaleString() + ' ₽';
  const roundPercent = round(value, 2) + ' %';
  const round1Percent = round(value) + ' %';
  const trp = round(value) + ' TRP';
  if (location === 'percent') {
    return roundPercent;
  } else if (location === 'round1percent') {
    return round1Percent;
  } else if (location === 'budget') {
    return roundBudget;
  } else if (location === 'trp') {
    return trp;
  } else {
    return value;
  }
};

export const findMarginChrono = chronoShareArr => {
  const marginChrono = +chronoShareArr[0].value * 0.3 + +chronoShareArr[1].value * 0.15 + +chronoShareArr[2].value * 0.05 + +chronoShareArr[3].value * 0 + +chronoShareArr[4].value * -0.02 + +chronoShareArr[5].value * -0.04;
  return marginChrono / 100;
};
